<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :submit_preprocessing="submit_preprocessing"
            :slot_table_list="['operation','class_rebate']"
            ref="list"
        >
            <template slot="class_rebate" slot-scope="data">
                {{ data.record.class_rebate == 'region' ? '区域' : '总部' }}
            </template>
            <template slot="operation" slot-scope="data">
                <a @click="details(data.record)">查看</a>
                <a-divider type="vertical" />
                <a-popconfirm
                    title="您确定要删除吗?"
                    v-auth="'delete'"
                    @confirm="del(data.record)"
                >
                    <a>删除</a>
                </a-popconfirm>
            </template>
        </TableList>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { contractList, delContract, getCostType} from "@/api/cost";
import { getType } from '@/api/approval'
let columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "合约名称",
        dataIndex: "title",
    },
    {
        title: "状态",
        dataIndex: "stataus_name",
    },
    {
        title: "签约业代所属部门",
        dataIndex: "path_name",
    },
    {
        title: "客户名称",
        dataIndex: "customer_name",
    },
    {
        title: "合约类型",
        dataIndex: "type_name",
    },
    {
        title: "返利口径",
        dataIndex: "class_rebate",
        scopedSlots: {
            customRender: "class_rebate"
        }
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
            get_table_list: contractList,
            submit_preprocessing: {
                array_to_string: ['department_id','type_id']
            },
            columns,
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department_id",
                        title: "签约业代所属部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "text",
                        name: "keyword",
                        title: "合约名称",
                        placeholder: '请输入合约名称',
                        options: {

                        }
                    },
                    {
                        type: "text",
                        name: "customer",
                        title: "客户名称",
                        placeholder: '请输入客户名称、客户ID',
                        options: {

                        }
                    },
                    {
                        type: 'select',
                        name: 'status',
                        title: '状态',
                        list: [
                            ...this.$config.status_all,
                            {
                                key: -1,
                                value: "已过期"
                            },
                            {
                                key: 1,
                                value: "期限中"
                            },
                            {
                                key: -2,
                                value: "未开始"
                            },
                            {
                                key: 3,
                                value: "已终止"
                            },
                            {
                                key: 2,
                                value: "已撤销"
                            },
                        ],
                        options: {},
                    },
                    {
                        type: "tree-select",
                        name: "type_id",
                        title: "合约类型",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    // {
                    //     type: "select",
                    //     name: "type_id",
                    //     title: "合约类型",
                    //     list: [],
                    //     options: {

                    //     }
                    // },
                    {
                        type: 'select',
                        name: 'class_rebate',
                        title: '返利口径',
                        list: [
                            {
                                key: 'region',
                                value: "区域"
                            },
                            {
                                key: 'headquarters',
                                value: "总部"
                            }
                        ],
                        options: {},
                    },
                ],
                ...this.$config.form_data_seo
            },

        };
    },
    async created() {
		// 缓存该页面
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department_id', 'treeData', res)
        });

        // getType({
        //     data: {
        //         type: 'investment'
        //     }
        // }).then(res => {
        //     let type_list = this.$method.to_form_list(res.data.list, 'id', 'name')
        //     this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'type_id', 'list', type_list)
        // })
        getCostType().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'type_id', 'treeData', res.data.list)
        })
    },
    methods: {
        details(data) {
			this.$keep_route.add(this.$options.name);
            this.$router.push('contract_detail?id=' + data.id)
        },
        del(data) {
            delContract({
                data: {
                    id: data.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
		/* report() {
			this.$router.push({
				path:"/report/modify_report",
				query:{
					report_key:"custom_cost_contract",
					...this.$refs.list.seo_data
				}
			});
		}, */
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>